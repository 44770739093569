import { createSlice } from '@reduxjs/toolkit';
import { SettingsInterface, TabInterface } from '../components/homepage/types';

export enum PositionStatus {
    COLLECTING = 'collecting',
    CONVERTING = 'converting',
    ERROR = 'Failed',
    NONE = '',
    REBALANCING = 'rebalancing',
}

export interface StoreInterface {
    settings: SettingsInterface
    tabs: TabInterface[]
    balance: {[key: string] : 
                {[key: string] : 
                    { [key: string]: string }}}  // address -> chainId -> symbol -> string
            
}

const initialState: StoreInterface = {
    settings: { autoCollect: false, autoRebalance: false, maxFee: 500, minFee: 5, percentOffset: 0, timeInterval: 10},
    tabs: [],
    balance: {}
};

export const mySlice = createSlice({
    name: 'status',
    initialState: initialState,
    reducers: {
        setTabsAction: (state, action) => {
            const { tabs } = action.payload;
            state.tabs =  tabs;
        },
        addTabAction: (state, action) => {
            state.tabs.push({...action.payload})
        },
        deleteTabAction: (state, action) => {
            const { tabId } = action.payload;
            state.tabs = state.tabs.filter(tab => tab._id !== tabId);
        },
        setBalanceAction: (state, action) => {
            state.balance = {...action.payload};
        },
        setSettingsAction: (state, action) => {
            state.settings = {...action.payload};
        },
        setTabSettingsAction: (state, action) => {
            const { tabId, settings } = action.payload;
            const tab = state.tabs.find(t => t._id === tabId);
            if (tab) {
                tab.settings = {...settings};
            }
        },
        setPositionSettingsAction: (state, action) => {
            const { tabId, positionMint, settings } = action.payload;
            const tab = state.tabs.find(t => t._id === tabId);
            const pos = tab?.positions.find(p => p.positionMint === positionMint);
            if (pos) {
                pos.settings = {...settings};
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSettingsAction, setBalanceAction, addTabAction, deleteTabAction, setTabsAction, setTabSettingsAction, setPositionSettingsAction } = mySlice.actions

export default mySlice.reducer