import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';

interface LogModalProps {
    logData: string;
    open: boolean;
    onClose: () => void;
}

export default function LogModal({ logData, open, onClose }: LogModalProps) {

    const handleClose = () => onClose();

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', maxWidth: '1400px', height: '80vh',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Box sx={{width: '100%', height: '100%'}}>
                     <textarea id="w3review" name="w3review" value={logData} readOnly={true} style={{width: '100%', height: '100%'}}/>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
